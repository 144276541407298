import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Section from '../components/Section';
import adrame from '../assets/images/client-thumbs/adrame-diongue.jpg';
import enoch from '../assets/images/client-thumbs/enoch-boakye.jpg';
import glenn from '../assets/images/client-thumbs/glenn-taylor-jr.jpg';
import jamal from '../assets/images/client-thumbs/jamal-shead.jpg';
import jalen from '../assets/images/client-thumbs/jalen-cook.jpg';
import jalenH from '../assets/images/client-thumbs/jalen-hill.jpg';
import keeshawn from '../assets/images/client-thumbs/keeshawn-bartthelemy.jpg';
import kyler from '../assets/images/client-thumbs/kyler-edwards.jpg';
import milos from '../assets/images/client-thumbs/milos-uzan.jpg';
import mouhamed from '../assets/images/client-thumbs/mouhamed-gueye.jpg';
import nate from '../assets/images/client-thumbs/nate-robinson.jpg';

const Main = styled.main`
  background: #fff;
  padding: 150px 4rem;
  color: #101119;
  line-height: 1.75;

  h1 {
    font-family: 'Inter';
    text-shadow: none;
    color: inherit;
    text-align: center;
  }

  h3 {
    font-weight: 500;
    max-width: 700px;
    font-size: 20px;
    text-align: center;
    margin: 1rem auto;
  }

  p {
    font-size: 16px;
    max-width: 700px;
    line-height: 1.75;
    margin: 3rem 0;
  }

  @media (max-width: 500px) {
    padding: 100px 2rem;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const ColSmall = styled.div`
  width: 33.33%;

  @media (min-width: 901px) and (max-width: 1200px) {
    width: 50%;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const ColLarge = styled.div`
  width: 66.66%;

  @media (min-width: 901px) and (max-width: 1200px) {
    width: 50%;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const GridItem = styled.div`
  margin: 1rem;
  line-height: 1.5;

  h3 {
    text-align: left;
    font-weight: bold;
  }

  p {
    margin: 0;
    font-size: 14px;
  }

  img {
    width: 100%;
  }

  &:hover {
    div[class*='ImageWrapper'] {
      clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
      transform: translateX(8%);
      transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
    }

    img {
      transform: translateX(0);
    }
  }

  @media (max-width: 900px) {
    height: auto;
  }
`;

const ClientsWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
`;

const ImageWrapper = styled.div`
  clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 0 50%, 0% 0%);
  transition: transform 300ms cubic-bezier(0.25, 1, 0.5, 1),
    clip-path 300ms cubic-bezier(0.25, 1, 0.5, 1);

  img {
    transition: transform 300ms cubic-bezier(0.25, 1, 0.5, 1);
  }
`;

const ClientsPage = () => {
  return (
    <Main>
      <Section>
        <ClientsWrapper>
          <h1>Clients</h1>

          <h3>We proudly represent elite athletes.</h3>

          <br />

          <Row>
            <ColSmall>
              <GridItem>
                <Link to="/clients/naterobinson">
                  <ImageWrapper>
                    <img src={nate} />
                  </ImageWrapper>
                </Link>
              </GridItem>
            </ColSmall>

            <ColSmall>
              <GridItem>
                <Link to="/clients/mouhamedgueye">
                  <ImageWrapper>
                    <img src={mouhamed} />
                  </ImageWrapper>
                </Link>
              </GridItem>
            </ColSmall>

            <ColSmall>
              <GridItem>
                <Link to="/clients/jalencook">
                  <ImageWrapper>
                    <img src={jalen} />
                  </ImageWrapper>
                </Link>
              </GridItem>
            </ColSmall>
          </Row>

          <Row>
            <ColSmall>
              <GridItem>
                <Link to="/clients/enochboakye">
                  <ImageWrapper>
                    <img src={enoch} />
                  </ImageWrapper>
                </Link>
              </GridItem>
            </ColSmall>

            <ColSmall>
              <GridItem>
                <Link to="/clients/keeshawnbarthelemy">
                  <ImageWrapper>
                    <img src={keeshawn} />
                  </ImageWrapper>
                </Link>
              </GridItem>
            </ColSmall>

            <ColSmall>
              <GridItem>
                <Link to="/clients/adramediongue">
                  <ImageWrapper>
                    <img src={adrame} />
                  </ImageWrapper>
                </Link>
              </GridItem>
            </ColSmall>
          </Row>

          <Row>
            <ColSmall>
              <GridItem>
                <Link to="/clients/glenntaylorjr">
                  <ImageWrapper>
                    <img src={glenn} />
                  </ImageWrapper>
                </Link>
              </GridItem>
            </ColSmall>

            <ColSmall>
              <GridItem>
                <Link to="/clients/jalenhill">
                  <ImageWrapper>
                    <img src={jalenH} />
                  </ImageWrapper>
                </Link>
              </GridItem>
            </ColSmall>

            <ColSmall>
              <GridItem>
                <Link to="/clients/milosuzan">
                  <ImageWrapper>
                    <img src={milos} />
                  </ImageWrapper>
                </Link>
              </GridItem>
            </ColSmall>
          </Row>
        </ClientsWrapper>
      </Section>
    </Main>
  );
};

export default ClientsPage;
